<template>
    <div class="express">
        <!-- 头部banner -->
        <banner :subtitle="subtitle" :maintitle="maintitle" :logoFlag="logoFlag" :imgSrc="imgSrc"></banner>
        <!-- 主体内容 -->
        <div class="express_cont">
            <div class="cont" v-class="'cont animate__animated animate__fadeInup animate__block'">
                <partTitle title="用车体验" subtitle="Experience"></partTitle>
                <div>
                    <swiper class="swiper first" :options="swiperOption">
                        <swiper-slide
                            v-for="(item,index) in swiper"
                            :key="index">
                            <div class="advantage-swiper_item">
                                <div class="bg">
                                    <img :src="item.icon" class="advantage-swiper_img"/>
                                </div>
                                <div class="advantage-swiper_bottom">
                                    <span>{{item.title}}</span>
                                    <!-- <p>{{item.mome}}</p> -->
                                </div>
                            </div>
                        </swiper-slide>
                    </swiper>
                    <!-- 如果需要分页器 -->
                    <div class="swiper-pagination"></div>
                </div>
            </div>
            <div class="cont" v-class="'cont animate__animated animate__fadeInup animate__block'">
                <partTitle title="用车场景" subtitle="Scenes"></partTitle>
                <div class="qy_cont">
                    <div class="part" v-class="'part animate__animated animate__fadeInup animate__block'" v-for="(item, index) in scenes" :key="index">
                        <div class="part_logo">
                            <img :src="item.icon" alt="">
                        </div>
                        <p>{{item.title}}</p>
                    </div>
                </div>
            </div>
        </div>
        <!-- 底部 -->
        <foot></foot>
    </div>
</template>

<script>
export default {
    data() {
        return {
            swiperOption:{
                pagination: '.swiper-pagination',
                paginationClickable: true,
                loop: true
            },
            logoFlag: false,
            maintitle: "企业用车",
            subtitle: "提供企业用车和管理解决方案，可通过后台实现行程和费用管控，简化流程，提升效率。",
            imgSrc: require('@/assets/images/qy-bg.png'),
            flag: false,
            swiper:[
                {
                    title:'专业服务',
                    icon:require('../../../static/images/qy/sfc-one.png'),
                    mome:'出行成本灵活可控，行程安全合规，路线透明'
                },
                {
                    title:'节约成本',
                    icon:require('../../../static/images/qy/sfc-two.png'),
                    mome:'企业账户统一支付，无需垫付报销'
                },
                {
                    title:'管理透明',
                    icon:require('../../../static/images/qy/sfc-three.png'),
                    mome:'专业司机规范服务，提供安全舒适乘车体验'
                }
            ],
            scenes: [{
                icon: require('../../../static/images/qy/one.png'),
                title: "会议用车"
            },{
                icon: require('../../../static/images/qy/two.png'),
                title: "商务接待"
            },{
                icon: require('../../../static/images/qy/three.png'),
                title: "差旅用车"
            },{
                icon: require('../../../static/images/qy/four.png'),
                title: "接送机"
            },{
                icon: require('../../../static/images/qy/five.png'),
                title: "员工加班"
            },]
        }
    },
    mounted(){
        this.routerTo(6);
    }
}
</script>

<style>
.mobile_swiper {
    position: relative;
}
.swiper .advantage-swiper_item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: .43rem;
    color: #2D2D39;
}
.swiper .advantage-swiper_item .bg {
    background: #F4F4F4;
    display: flex;
    align-items: center;
    justify-content: center;
}
.swiper .advantage-swiper_item .advantage-swiper_bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 .48rem;
}
.swiper .advantage-swiper_item .advantage-swiper_bottom span {
    font-size: .36rem;
    margin: .5rem 0 .21rem;
}
.swiper .advantage-swiper_item .advantage-swiper_bottom p {
    font-size: .24rem;
}
.swiper-pagination {
    position: relative;
    display: flex;
    justify-content: center;
    margin: .45rem auto;
}
.swiper-pagination .swiper-pagination-bullet {
    margin: 0 .1rem;
    background: #CBCBCB;
    opacity: 1;
}
.swiper-pagination-bullet-active {
    background: #676767!important;
    opacity: 1;
}
</style>
<style scoped>
.animate__block{
    visibility: visible !important;
}
.animate__animated{
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-duration: var(--animate-duration);
    animation-duration: var(--animate-duration);
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    animation-delay:0.2s;
}
.animate__fadeInup{
    -webkit-animation-name: FadeInup;
    animation-name: FadeInup;
}
.animate__animated {
  --animate-duration: .5s;
}
.express_cont {
    margin-bottom: 2rem;
}
.express .express_cont .cont {
    margin-top: .9rem;
    visibility: hidden;
}
.first .bg,
.first .bg img {
    width: 5.4rem;
    height: 3.1rem;
}
.cont .qy_cont {
    display: flex;
    flex-wrap: wrap;
    padding: .48rem .48rem 0;
    box-sizing: border-box;
    justify-content: space-between;
}
.cont .qy_cont .part {
    width: 3.07rem;
    background: #F4F4F4;
    margin-bottom: .35rem;
    visibility: hidden;
}
.cont .qy_cont .part .part_logo {
    background: #626279;
    border-radius: 50%;
    width: 1.85rem;
    height: 1.85rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: .48rem auto;
}
.cont .qy_cont .part .part_logo img {
    width: 1rem;
    height: 1rem;
}
.cont .qy_cont .part p {
    height: .9rem;
    text-align: center;
    line-height: .9rem;
    background: #E5E5E5;
    font-size: .28rem;
}
@keyframes FadeInup {
    0%{
        opacity: 0;
        -webkit-transform: translateY(30%);
        transform: translateY(30%);
    }
    100%{
        opacity:1;
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}
</style>